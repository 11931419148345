import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import TradeleadStepper from './TradeleadStepper';
import ImageSlider from './ImageSlider';

function Hero() {
    useEffect(() => {
        const blockContainer = document.getElementById("blocks");
        const blockSize = 50;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const numCols = Math.ceil(screenWidth / blockSize);
        const numRows = Math.ceil(screenHeight / blockSize);
        const numBlocks = numCols * numRows;

        function createBlocks() {
            for (let i = 0; i < numBlocks; i++) {
                const block = document.createElement("div");
                block.classList.add("block");
                block.dataset.index = i;
                block.addEventListener("mousemove", highlightBlocks);
                blockContainer.appendChild(block);
            }
        }

        function highlightBlocks() {
            const index = parseInt(this.dataset.index);
            const neighbors = [
                index - 1,
                index + 1,
                index - numCols,
                index + numCols,
                index - numCols - 1,
                index - numCols + 1,
                index + numCols - 1,
                index + numCols + 1,
            ].filter((i) => i >= 0 && i < numBlocks && Math.abs((i % numCols) - (index % numCols)) <= 1);

            this.classList.add("highlight");
            setTimeout(() => {
                this.classList.remove("highlight");
            }, 500);

            shuffleArray(neighbors).slice(0, 1).forEach((nIndex) => {
                const neighbor = blockContainer.children[nIndex];
                if (neighbor) {
                    neighbor.classList.add("highlight");
                    setTimeout(() => {
                        neighbor.classList.remove("highlight");
                    }, 500);
                }
            });
        }

        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        }

        createBlocks();
    }, []);

    return (
        <div className='heroDiv'>

            <div className="blocksContainer">
                <div id="blocks"></div>
            </div>

            <div className="heroContent">
                <div className="heroLeft">
                    <div className="heroContentMain">
                        <h1>EMPOWER YOUR <span>FAITH</span></h1>
                        <p>Explore a vast selection of religious products and services from trusted sellers.</p>
                        <div className="heroBtns">
                            <Link to="/products" className='luxuryBtn'>Explore Products <i className="fa-solid fa-arrow-right"></i></Link>
                            <Link to="/services" className='luxuryBtn'>Find Services <i className="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="heroTradeleadFormDiv">
                        <TradeleadStepper />
                    </div>
                </div>
                <div className="heroRight">
                    <ImageSlider />
                </div>
            </div>
        </div>
    );
}

export default Hero;