import React, { useEffect, useState } from 'react';

const images = [
    '/assets/images/hero6.jpg',
    '/assets/images/hero7.png',
    '/assets/images/hero8.png',
    '/assets/images/hero9.png',
    '/assets/images/hero10.png',
    '/assets/images/hero11.png',
];

function ImageSlider() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true);

            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
                setIsFading(false);
            }, 500);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="imageSlider">
            <img
                src={images[currentIndex]}
                alt="Slide"
                className={`slideImage ${isFading ? 'fadeOut' : 'fadeIn'}`}
            />
            <div className="dotsContainer">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
}

export default ImageSlider;
